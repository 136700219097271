<template>
  <div class="relative flex items-center justify-center min-h-screen overflow-hidden" id="home">
    <img src="@/assets/headerbg.jpg" class="absolute lg:hidden w-auto min-w-full min-h-full max-w-none brightness-40">
    <video autoplay loop muted class="absolute hidden lg:flex w-auto min-w-full min-h-full max-w-none brightness-40">
      <source src="@/assets/headerbg.mp4" type="video/mp4"/>
      Your browser does not support the video tag.
    </video>
    <div class="absolute hidden lg:flex inset-0" style="background:url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAFCAYAAACNbyblAAAAIklEQVQIW2NkwAIYkcTOANkmID5MECRgDMRnQRJ4VaKYDADKYAQGYTcblgAAAABJRU5ErkJggg==) repeat;"></div>
    <div class="relative px-6 sm:px-10 md:px-16 py-36 text-white">
      <h1 class="text-center text-2xl font-bold tracking-tight sm:text-4xl" v-html="t('home.title')"></h1>
      <p class="mt-4 leading-8 indent-8">{{ t('home.offer') }}</p>
      <ul class="mt-3 space-y-1 list-disc list-inside dark:text-gray-400">
          <li>
            {{ t('home.services.biot') }}
          </li>
          <li>
            {{ t('home.services.prombez') }}
          </li>
          <li>
            {{ t('home.services.pozhbez') }}
          </li>
          <li>
            {{ t('home.services.elbez') }}
          </li>
      </ul>
      <div class="mt-9 flex flex-col md:flex-row items-center justify-center gap-x-5 gap-y-5">
        <a :href="`https://wa.me/+77012012441?text=${t('home.hello')}%2C%20`" style="background-color: #25D366;" class="rounded-md px-3.5 py-1.5 text-base font-semibold leading-7 text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"><font-awesome-icon :icon="['fab', 'whatsapp']" /> {{ t('home.whatsapp') }}</a>
        <a href="#about-us" class="text-base font-semibold leading-7 hover:text-amber-600">{{ t('home.more') }} <span aria-hidden="true"></span></a>
      </div>
    </div>
    <div class="absolute top-0 inset-x-0">
      <Header :dark="true" />
    </div>
  </div>

  <div class="bg-white py-12 sm:py-24 lg:py-48" id="about-us">
    <div class="mx-auto max-w-7xl px-6 lg:px-8">
      <div class="mx-auto max-w-4xl text-center">
        <p class="mt-2 text-2xl font-bold tracking-tight text-gray-900 sm:text-4xl">{{ t('about-us.title') }}</p>
        <p class="mt-6 leading-8 text-gray-600 text-left indent-8">{{ t('about-us.text.0') }}</p>
        <p class="mt-1 leading-8 text-gray-600 text-left indent-8">{{ t('about-us.text.1') }}</p>
        <p class="mt-1 leading-8 text-gray-600 text-left indent-8">{{ t('about-us.text.2') }}</p>
        <p class="mt-1 leading-8 text-gray-600 text-left indent-8">{{ t('about-us.text.3') }}</p>
      </div>
      <div class="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-4xl">
        <dl class="grid max-w-xl grid-cols-1 gap-y-10 gap-x-8 lg:max-w-none lg:grid-cols-2 lg:gap-y-16">
          <div v-for="feature in features" :key="feature.name" class="relative pl-16">
            <dt class="text-base font-semibold leading-7 text-gray-900">
              <div class="absolute top-0 left-0 flex h-10 w-10 items-center justify-center rounded-lg bg-blue-600">
                <component v-if="!feature.text" :is="feature.icon" class="h-6 w-6 text-white" aria-hidden="true" />
                <span v-if="feature.text" class="text-white">{{ feature.text }}</span>
              </div>
              {{ feature.name.value }}
            </dt>
            <dd class="mt-2 text-base leading-7 text-gray-600">{{ feature.description.value }}</dd>
          </div>
        </dl>
        <div class="flex justify-center mt-10">
          <a :href="`https://wa.me/+77012012441?text=${t('home.hello')}%2C%20`" style="background-color: #25D366;" class="rounded-md px-3.5 py-1.5 text-base font-semibold leading-7 text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"><font-awesome-icon :icon="['fab', 'whatsapp']" /> {{ t('home.whatsapp') }}</a>
        </div>
      </div>
      <div class="mx-auto max-w-4xl text-center">
        <div class="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-4xl">
          <p class="mt-6 leading-8 text-gray-600 text-left indent-8">{{ t('about-us.text.4') }}</p>
          <a id="image" :href="require('@/assets/about-us-1.jpg')" data-pswp-width="2465" data-pswp-height="1388" target="_blank">
            <img class="h-auto max-w-full md:w-3/4 mt-8 mx-auto" src="@/assets/about-us-1.jpg" :alt="t('about-us.images.0')">
          </a>
          <p class="mt-6 leading-8 text-gray-600 text-left indent-8">{{ t('about-us.text.5') }}</p>
          <a id="image" :href="require('@/assets/about-us-2.jpg')" data-pswp-width="2256" data-pswp-height="1269" target="_blank">
            <img class="h-auto max-w-full md:w-3/4 mt-8 mx-auto mt-8 mx-auto" src="@/assets/about-us-2.jpg" :alt="t('about-us.images.1')">
          </a>
          <p class="mt-6 leading-8 text-gray-600 text-left indent-8">{{ t('about-us.text.6') }} <a class="text-blue-400" href="https://cabinet.asia 2012.kz ">cabinet.azia2012.kz </a>.</p>
          <a id="image" :href="require('@/assets/about-us-3.jpg')" data-pswp-width="2256" data-pswp-height="1269" target="_blank">
            <img class="h-auto max-w-full md:w-3/4 mt-8 mx-auto" src="@/assets/about-us-3.jpg" :alt="t('about-us.images.2')">
          </a>
        </div>
      </div>
    </div>
  </div>

  <div class="bg-white py-12 sm:py-24 lg:py-48">
    <div class="mx-auto max-w-7xl px-6 lg:px-8">
      <div class="mx-auto max-w-4xl text-center">
        <p class="mt-2 text-2xl font-bold tracking-tight text-gray-900 sm:text-4xl">{{ t('our-lecturers.title') }}</p>
        <p class="mt-9 leading-8 text-gray-600 text-left indent-8"><b>{{ t('our-lecturers.lecturers.0.name') }}</b>{{ t('our-lecturers.lecturers.0.description') }}</p>
        <p class="mt-5 leading-8 text-gray-600 text-left indent-8"><b>{{ t('our-lecturers.lecturers.1.name') }}</b>{{ t('our-lecturers.lecturers.1.description') }}</p>
        <p class="mt-5 leading-8 text-gray-600 text-left indent-8"><b>{{ t('our-lecturers.lecturers.2.name') }}</b>{{ t('our-lecturers.lecturers.2.description') }}</p>
        <p class="mt-5 leading-8 text-gray-600 text-left indent-8"><b>{{ t('our-lecturers.lecturers.3.name') }}</b>{{ t('our-lecturers.lecturers.3.description') }}</p>
      </div>
    </div>
  </div>

  <div class="bg-white py-12 sm:py-24 lg:py-48" id="prices">
    <div class="mx-auto max-w-7xl px-6 lg:px-8">
      <div class="mx-auto max-w-4xl text-left">
        <p class="mt-2 text-2xl font-bold tracking-tight text-gray-900 sm:text-4xl text-center">{{ t('prices.title') }}</p>
        <table class="mt-9 leading-8 text-gray-600">
          <thead>
            <tr>
              <th class="w-full px-2">{{ t('prices.course') }}</th>
              <th class="whitespace-nowrap px-2">{{ t('prices.price') }}</th>
            </tr>
          </thead>
          <tbody>
            <tr class="bg-slate-100">
              <td class="w-full px-2">{{ t('prices.courses.0.name') }}</td>
              <td class="whitespace-nowrap px-2">{{ t('prices.courses.0.price', { price: '25 000' }) }}</td>
            </tr>
            <tr>
              <td class="w-full px-2">{{ t('prices.courses.1.name') }}</td>
              <td class="whitespace-nowrap px-2">{{ t('prices.courses.1.price', { price: '20 000' }) }}</td>
            </tr>
            <tr class="bg-slate-100">
              <td class="w-full px-2">{{ t('prices.courses.2.name') }}</td>
              <td class="whitespace-nowrap px-2">{{ t('prices.courses.2.price', { price: '15 000' }) }}</td>
            </tr>
            <tr>
              <td class="w-full px-2">{{ t('prices.courses.3.name') }}</td>
              <td class="whitespace-nowrap px-2">{{ t('prices.courses.3.price', { price: '20 000' }) }}</td>
            </tr>
            <tr class="bg-slate-100">
              <td class="w-full px-2">{{ t('prices.courses.4.name') }}</td>
              <td class="whitespace-nowrap px-2">{{ t('prices.courses.4.price', { price: '25 000' }) }}</td>
            </tr>
            <tr>
              <td class="w-full px-2">{{ t('prices.courses.5.name') }}</td>
              <td class="whitespace-nowrap px-2">{{ t('prices.courses.5.price', { price: '25 000' }) }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>

  <div class="bg-white py-12 sm:py-24 lg:py-48" id="contacts">
    <div class="mx-auto max-w-7xl px-6 lg:px-8">
      <div class="mx-auto max-w-4xl text-center">
        <p class="mt-2 text-2xl font-bold tracking-tight text-gray-900 sm:text-4xl">{{ t('contacts.title') }}</p>
        <p class="mt-9 leading-8 text-gray-600 text-left align-middle">
          <component :is="BuildingOffice2Icon" class="h-6 w-6 inline-block" aria-hidden="true" />
          {{ t('contacts.address') }}
        </p>
        <p class="w-full md:w-1/3 inline-block mt-5 leading-8 text-gray-600 text-left">
          <component :is="AtSymbolIcon" class="h-6 w-6 inline-block" aria-hidden="true" />
          <a class="text-blue-400 ml-1" href="mailto:azia_2012@inbox.ru&subject='Письмо с сайта azia2012.kz'">azia_2012@inbox.ru</a>
        </p>
        <p class="w-full md:w-1/3 inline-block mt-5 leading-8 text-gray-600 text-left">
          <component :is="PhoneIcon" class="h-6 w-6 inline-block" aria-hidden="true" />
          <a class="text-blue-400 ml-1" href="tel:+77172793147">+7 (7172) 79 31 47</a>
        </p>
        <p class="w-full md:w-1/3 inline-block mt-5 leading-8 text-gray-600 text-left">
          <component :is="DevicePhoneMobileIcon" class="h-6 w-6 inline-block" aria-hidden="true" />
          <a class="text-blue-400 ml-1" href="tel:+77012012441">+7 (701) 2012 441</a>
        </p>
        <p class="w-full md:w-1/3 inline-block mt-5 leading-8 text-gray-600 text-left">
          <font-awesome-icon :icon="['fab', 'whatsapp']" class="w-6 h-6 inline-block align-middle" />
          <a class="text-blue-400 ml-1 align-middle" :href="`https://wa.me/+77012012441?text=${t('home.hello')}%2C%20`">+7 (701) 2012 441</a>
        </p>
        <div id="map" class="mt-7" style="width:100%; height:450px"></div>
      </div>
    </div>
  </div>

  <div class="bg-white py-12 sm:py-24 lg:py-48" id="requisites">
    <div class="mx-auto max-w-7xl px-6 lg:px-8">
      <div class="mx-auto max-w-4xl text-left">
        <p class="mt-2 text-2xl font-bold tracking-tight text-gray-900 sm:text-4xl text-center">{{ t('requisites.title') }}</p>
        <table class="mt-9 leading-8 text-gray-600">
          <thead>
            <tr>
              <th class="w-full px-2" colspan="2">{{ t('requisites.company') }}</th>
            </tr>
          </thead>
          <tbody>
            <tr class="bg-slate-100">
              <td class="px-2 font-bold">{{ t('requisites.address.name') }}</td>
              <td class="w-full px-2">{{ t('requisites.address.value') }}</td>
            </tr>
            <tr>
              <td class="px-2 font-bold">{{ t('requisites.bin.name') }}</td>
              <td class="w-full px-2">120140014929</td>
            </tr>
            <tr class="bg-slate-100">
              <td class="px-2 font-bold">{{ t('requisites.bank.name') }}</td>
              <td class="w-full px-2">{{ t('requisites.bank.value') }}</td>
            </tr>
            <tr>
              <td class="px-2 font-bold">{{ t('requisites.iik.name') }}</td>
              <td class="w-full px-2">KZ489650000070398348</td>
            </tr>
            <tr class="bg-slate-100">
              <td class="px-2 font-bold">{{ t('requisites.bik.name') }}</td>
              <td class="w-full px-2">IRTYKZKA</td>
            </tr>
            <tr>
              <td class="px-2 font-bold">{{ t('requisites.kbe.name') }}</td>
              <td class="w-full px-2">17</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <Modal>
    <Login />
  </Modal>
  <Footer />
</template>

<script setup>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import Login from '@/components/Login.vue'
import Modal from '@/components/Modal.vue'
import { computed, onMounted } from 'vue'
import { useI18n } from 'vue-i18n'
import PhotoSwipeLightbox from 'photoswipe/lightbox';
import PhotoSwipe from 'photoswipe';
import 'photoswipe/style.css';
import {
  ArrowPathIcon,
  GlobeAltIcon,
  BuildingOfficeIcon,
  UserGroupIcon,
  BuildingOffice2Icon,
  AtSymbolIcon,
  PhoneIcon,
  DevicePhoneMobileIcon
} from '@heroicons/vue/24/outline'


const { t } = useI18n({
  sync: true,
  messages: {
    ru: {
      'home': {
        'title': 'Учебный&nbsp;центр&nbsp; ТОО&nbsp;«Компания&nbsp;АЗиЯ&nbsp;2012»',
        'offer': 'Предлагает услуги по обучению с последующей выдачей документов установленного образца по курсам:',
        'services': {
          'biot': 'Безопасность и охрана труда на предприятиях',
          'prombez': 'Промышленная безопасность на предприятиях',
          'pozhbez': 'Пожарная безопасность в объеме пожарно-технического минимума',
          'elbez': 'Электробезопасность на предприятиях',
        },
        'order': 'Подать заявку',
        'whatsapp': 'Написать в WhatsApp',
        'hello': 'Здравствуйте',
        'more': 'Узнать больше',
      },
      'about-us': {
        'title': 'О нас',
        'images': {
          '0': 'Конференц-зал',
          '1': 'Онлайн обучение',
          '2': 'Онлайн тестирование (экзамен)',
        },
        'incentives': {
          '0': {
            'title': 'Опыт',
            'text': 'Более 10 лет опыта работы в данной сфере',
          },
          '1': {
            'title': 'Лектора',
            'text': 'Квалифицированный лекторский состав',
          },
          '2': {
            'title': 'Расположение',
            'text': 'Современный бизнес-центр Korgalzhyn',
          },
          '3': {
            'title': 'Онлайн',
            'text': 'Возможность онлайн обучения',
          },
        },
        'text': {
          '0': 'Наш учебный центр был основан в 2012 году. И вот уже более 10 лет успешно функционирует. За это время мы накопили огромный опыт работы в данной сфере.',
          '1': 'Основная задача нашей компании – развитие культуры безопасности во всех отраслях, на предприятиях и в организациях. Переход на новый, современный уровень работы.',
          '2': 'Мы активно внедряем и используем новые технологии для обучения и проверки знаний наших клиентов.',
          '3': 'Обучение проводят представители министерств и ведомств, специалисты крупных промышленных предприятий и организаций, доктора и кандидаты технических наук.',
          '4': 'В зависимости от пожелания заказчика обучение может проводиться: на территории заказчика, в нашем конференц-зале (расположенном в бизнес-центре Korgalzhyn) и/или в онлайн формате.',
          '5': 'Слушатели обеспечиваются раздаточным материалом. При оффлайн занятиях на нашей территории предоставляется питьевая вода 0.5л и так же предусмотрен перерыв на кофе-брейк.',
          '6': 'Тестирование сдается через личный кабинет веб-платформы',
        },
      },
      'our-lecturers': {
        'title': 'Наши лектора',
        'lecturers': {
          '0': {
            'name': 'Аренова Галина Станиславовна',
            'description': ' - эксперт в области безопасности и охраны труда, по специальности строитель – архитектор, стаж работы более 45 лет, стаж инженера по БИОТ более 15 лет, ранее научный сотрудник РНИИОТ МТСЗН РК.',
          },
          '1': {
            'name': 'Бадуанов Кайрат Рыспекович',
            'description': ' - эксперт в области промышленной безопасности, инженер механик, стаж работы более 40 лет, ранее работал заместителем начальника Управления по госконтролю за чрезвычайными ситуациями и промышленной безопасностью, затем начальником Управления го госконтролю в области промышленной безопасности ДЧС г. Астана.',
          },
          '2': {
            'name': 'Усабай Толеген Темешевич',
            'description': ' - эксперт в области пожарной безопасности, инженер пожарной безопасности, стаж работы более 26 лет, ранее работал начальником отдела ДЧС г. Астана.',
          },
          '3': {
            'name': 'Иващенко Ирина Анатольевна',
            'description': ' - эксперт в области электробезопасности, по специальности инженер электрик, стаж более 30 лет, лекторской деятельности – более 20 лет.',
          },
        },
      },
      'prices': {
        'title': 'Прайс лист',
        'course': 'Курс',
        'price': 'Цена/чел.',
        'courses': {
          '0': {
            'name': 'Безопасность и охрана труда',
            'price': '{price} тг.',
          },
          '1': {
            'name': 'Промышленная безопасность (руководители и инженерно-технические работники)',
            'price': '{price} тг.',
          },
          '2': {
            'name': 'Промышленная безопасность (работники и рабочие)',
            'price': '{price} тг.',
          },
          '3': {
            'name': 'Пожарная безопасность ',
            'price': '{price} тг.',
          },
          '4': {
            'name': 'Электробезопасность',
            'price': '{price} тг.',
          },
          '5': {
            'name': 'Курсы переподготовки кадров по рабочим профессиям',
            'price': '{price} тг.',
          },
        },
      },
      'contacts': {
        'title': 'Контакты',
        'address': 'РК, г. Астана, шоссе Коргалжын, д. 19 (БЦ Korgalzhyn), сектор А, оф. 200',
      },
      'requisites': {
        'title': 'Реквизиты',
        'company': 'ТОО "Компания АЗиЯ 2012"',
        'address': {
          'name': 'Юр. адрес',
          'value': 'РК, г. Астана, р. Есиль, шоссе Коргалжын, д. 19, сектор А, оф. 200',
        },
        'bin': {
          'name': 'БИН',
        },
        'bank': {
          'name': 'Банк',
          'value': 'АО «ForteBank»',
        },
        'iik': {
          'name': 'ИИК',
        },
        'bik': {
          'name': 'БИК',
        },
        'kbe': {
          'name': 'КБе',
        },
      },
    },
    kk: {
      'home': {
        'title': '«Компания&nbsp;АЗиЯ&nbsp;2012»&nbsp;ЖШС оқу&nbsp;орталығы&nbsp;',
        'offer': 'Кейіннен курстар бойынша белгіленген үлгідегі құжаттарды бере отырып оқыту бойынша қызметтерді ұсынады:',
        'services': {
          'biot': 'Кәсіпорындардағы еңбек қауіпсіздігі және еңбекті қорғау',
          'prombez': 'Кәсіпорындардағы өнеркәсіптік қауіпсіздік',
          'pozhbez': 'Өрт-техникалық минимум көлеміндегі өрт қауіпсіздігі',
          'elbez': 'Кәсіпорындардағы электр қауіпсіздігі',
        },
        'order': 'Өтініш беру',
        'whatsapp': 'WhatsApp\'қа жазу',
        'hello': 'Сәлеметсіз бе',
        'more': 'Көбірек біліңіз',
      },
      'about-us': {
        'title': 'Біз туралы',
        'images': {
          '0': 'Конференц-зал',
          '1': 'Онлайн оқыту',
          '2': 'Онлайн тестілеу (емтихан)',
        },
        'incentives': {
          '0': {
            'title': 'Тәжірибе',
            'text': 'Осы саладағы 10 жылдан астам жұмыс тәжірибесі',
          },
          '1': {
            'title': 'Дәріскер',
            'text': 'Білікті лекторлық құрам',
          },
          '2': {
            'title': 'Орналасқан жері',
            'text': 'Заманауи Korgalzhyn бизнес орталығы',
          },
          '3': {
            'title': 'Онлайн',
            'text': 'Онлайн оқыту мүмкіндігі',
          },
        },
        'text': {
          '0': 'Біздің оқу орталығы 2012 жылы құрылды. Ал қазір 10 жылдан астам уақыт бойы табысты жұмыс істеп келеді. Осы уақыт ішінде біз осы салада үлкен тәжірибе жинақтадық.',
          '1': 'Біздің компанияның негізгі міндеті-барлық салаларда, кәсіпорындарда және ұйымдарда қауіпсіздік мәдениетін дамыту. Жаңа, заманауи жұмыс деңгейіне көшу.',
          '2': 'Біз өз клиенттеріміздің білімін тексеру және оқыту үшін жаңа технологияларды белсенді түрде енгіземіз және қолданамыз.',
          '3': 'Оқытуды министрліктер мен ведомстволардың өкілдері, ірі өнеркәсіптік кәсіпорындар мен ұйымдардың мамандары, техника ғылымдарының докторлары мен кандидаттары жүргізеді.',
          '4': 'Тапсырыс берушінің тілегіне байланысты оқыту: Тапсырыс берушінің аумағында, Біздің конференц-залда (Korgalzhyn бизнес-орталығында орналасқан) және/немесе онлайн форматта жүргізілуі мүмкін.',
          '5': 'Тыңдаушылар үлестірме материалмен қамтамасыз етіледі. Біздің аумақта офлайн сабақтар кезінде 0.5 л ауыз су беріледі және кофе-брейкке үзіліс қарастырылған.',
          '6': 'Тестілеу веб-платформаның жеке кабинеті арқылы тапсырылады',
        },
      },
      'our-lecturers': {
        'title': 'Біздің дәріскерлер',
        'lecturers': {
          '0': {
            'name': 'Аренова Галина Станиславовна',
            'description': ' – еңбек қауіпсіздігі және еңбекті қорғау саласындағы сарапшы, құрылысшы-сәулетші мамандығы бойынша жұмыс өтілі 45 жылдан астам, БИОТ бойынша инженер өтілі 15 жылдан астам, бұрын ҚР ЕХӘҚМ РҒЗИ ғылыми қызметкері.',
          },
          '1': {
            'name': 'Бадуанов Кайрат Рыспекович',
            'description': ' - өнеркәсіптік қауіпсіздік саласындағы сарапшы, инженер механик, жұмыс өтілі 40 жылдан астам, бұған дейін Төтенше жағдайлар мен өнеркәсіптік қауіпсіздікті мемлекеттік бақылау басқармасы бастығының орынбасары, кейін Астана қаласы ТЖД өнеркәсіптік қауіпсіздік саласындағы мемлекеттік бақылау АҚ басқармасының бастығы болып жұмыс істеген.',
          },
          '2': {
            'name': 'Усабай Толеген Темешевич',
            'description': ' - өрт қауіпсіздігі саласындағы сарапшы, өрт қауіпсіздігі инженері, жұмыс өтілі 26 жылдан астам, бұған дейін Астана қаласы ТЖД бөлімінің бастығы болып жұмыс істеген.',
          },
          '3': {
            'name': 'Иващенко Ирина Анатольевна',
            'description': ' - электр қауіпсіздігі саласындағы сарапшы, инженер электрик мамандығы бойынша, 30 жылдан астам еңбек өтілі, лекторлық қызметі – 20 жылдан астам.',
          },
        },
      },
      'prices': {
        'title': 'Баға тізімі',
        'course': 'Курс',
        'price': 'Бағасы/адам',
        'courses': {
          '0': {
            'name': 'Еңбек қауіпсіздігі және еңбекті қорғау',
            'price': '{price} тг.',
          },
          '1': {
            'name': 'Өнеркәсіптік қауіпсіздік (басшылар және инженерлік-техникалық қызметкерлер)',
            'price': '{price} тг.',
          },
          '2': {
            'name': 'Өнеркәсіптік қауіпсіздік (жұмысшылар мен жұмысшылар)',
            'price': '{price} тг.',
          },
          '3': {
            'name': 'Өрт қауіпсіздігі',
            'price': '{price} тг.',
          },
          '4': {
            'name': 'Электр қауіпсіздігі',
            'price': '{price} тг.',
          },
          '5': {
            'name': 'Көгілдір мамандықтарды қайта даярлау курстары',
            'price': '{price} тг.',
          },
        },
      },
      'contacts': {
        'title': 'Байланыс',
        'address': 'ҚР, Астана қ., Қорғалжын тас жолы 19 (Korgalzhyn БО), A секторы, кеңсе 200',
      },
      'requisites': {
        'title': 'Деректемелер',
        'company': '"Компания АЗиЯ 2012" ЖШС',
        'address': {
          'name': 'Заңды мекен-жайы',
          'value': 'ҚР, Астана қ., Есіл ауданы, Қорғалжын тас жолы 19, A секторы, кеңсе 200',
        },
        'bin': {
          'name': 'БИН',
        },
        'bank': {
          'name': 'Банк',
          'value': '«ForteBank» АҚ',
        },
        'iik': {
          'name': 'ИИК',
        },
        'bik': {
          'name': 'БИК',
        },
        'kbe': {
          'name': 'КБе',
        },
      },
    },
    en: {
      'home': {
        'title': 'Educational&nbsp;center "Companiya&nbsp;AZiYA&nbsp;2012"',
        'offer': 'Offers education services with the subsequent issuance of standard documents for courses:',
        'services': {
          'biot': 'Occupational safety and health at enterprises',
          'prombez': 'Industrial safety at enterprises',
          'pozhbez': 'Fire safety in the scope of the fire-technical minimum',
          'elbez': 'Electrical safety in enterprises',
        },
        'order': 'Submit an application',
        'whatsapp': 'Text to WhatsApp',
        'hello': 'Hello',
        'more': 'Learn more',
      },
      'about-us': {
        'title': 'About us',
        'images': {
          '0': 'Conference hall',
          '1': 'Online lesson',
          '2': 'Online testing (exam)',
        },
        'incentives': {
          '0': {
            'title': 'Experience',
            'text': 'More than 10 years of experience in this field',
          },
          '1': {
            'title': 'Lecturer',
            'text': 'Qualified lecturers',
          },
          '2': {
            'title': 'Location',
            'text': 'Modern business center Korgalzhyn',
          },
          '3': {
            'title': 'Online',
            'text': 'The possibility of online learning',
          },
        },
        'text': {
          '0': 'Our educational center was founded in 2012. And it has been successfully functioning for more than 10 years. During this time, we have accumulated extensive experience in this field.',
          '1': 'The main task of our company is to develop a safety culture in all industries, enterprises and organizations. Transition to a new, modern level of work.',
          '2': 'We are actively implementing and using new technologies to train and test the knowledge of our customers.',
          '3': 'The training is conducted by representatives of ministries and departments, specialists of large industrial enterprises and organizations, doctors and candidates of technical sciences.',
          '4': 'Depending on the customer\'s wishes, training can be conducted: on the customer\'s premises, in our conference hall (located in the Korgalzhyn business center) and/or in an online format.',
          '5': 'Listeners are provided with handouts. During offline classes, 0.5 liters of drinking water is provided on our territory and a coffee break is also provided.',
          '6': 'Testing is done through the personal account of the web platform',
        },
      },
      'our-lecturers': {
        'title': 'Our lecturers',
        'lecturers': {
          '0': {
            'name': 'Arenova Galina Stanislavovna',
            'description': ' - an expert in the field of occupational safety and health, a builder – architect by profession, work experience of more than 45 years, experience as a BIOT engineer for more than 15 years, previously a researcher at the RNIIOT of the Ministry of Health and Science of the Republic of Kazakhstan.',
          },
          '1': {
            'name': 'Baduanov Kajrat Ryspekovich',
            'description': ' - expert in the field of industrial safety, mechanical engineer, work experience of more than 40 years, previously worked as deputy head of the Department for State Control over Emergencies and Industrial Safety, then head of the Department of State Control in the field of Industrial safety of the City of Emergency Situations. Astana.',
          },
          '2': {
            'name': 'Usabaj Tolegen Temeshevich',
            'description': ' - an expert in the field of fire safety, a fire safety engineer, work experience of more than 26 years, previously worked as the head of the department of emergency situations in Astana.',
          },
          '3': {
            'name': 'Ivashchenko Irina Anatol\'evna',
            'description': ' - an expert in the field of electrical safety, specializing in electrical engineer, experience of more than 30 years, lecturing activity – more than 20 years.',
          },
        },
      },
      'prices': {
        'title': 'Prices',
        'course': 'Course',
        'price': 'Price',
        'courses': {
          '0': {
            'name': 'Occupational safety and health',
            'price': '{price} KZT',
          },
          '1': {
            'name': 'Industrial safety (managers and engineering and technical workers)',
            'price': '{price} KZT',
          },
          '2': {
            'name': 'Industrial safety (workers and workers)',
            'price': '{price} KZT',
          },
          '3': {
            'name': 'Fire safety',
            'price': '{price} KZT',
          },
          '4': {
            'name': 'Electrical safety',
            'price': '{price} KZT',
          },
          '5': {
            'name': 'Retraining courses for blue-collar workers',
            'price': '{price} KZT',
          },
        },
      },
      'contacts': {
        'title': 'Contacts',
        'address': 'Republic of Kazakhstan, Astana city, highway Korgalzhyn 19, BC Korgalzhyn, sector A, office 200',
      },
      'requisites': {
        'title': 'Requisites',
        'company': '"Companiya AZiYA 2012"',
        'address': {
          'name': 'Legal address',
          'value': 'Republic of Kazakhstan, Astana city, Yesil district, highway Korgalzhyn 19, BC Korgalzhyn, sector A, office 200',
        },
        'bin': {
          'name': 'BIN',
        },
        'bank': {
          'name': 'Bank',
          'value': '«ForteBank»',
        },
        'iik': {
          'name': 'IIK',
        },
        'bik': {
          'name': 'BIK',
        },
        'kbe': {
          'name': 'Beneficiary Code',
        },
      },
    },
  }
})

onMounted(() => {
  var map;

  DG.then(function () {
      map = DG.map('map', {
          center: [51.146747, 71.371809],
          zoom: 16,
          dragging: false,
          touhchzoom: false,
          scrollWheelZoom: false,
          doubleClickZoom: false,
          boxZoom: false,
          geoclicker: false,
      });
      DG.marker([51.146747, 71.371809]).addTo(map);
  });

  const lightbox = new PhotoSwipeLightbox({
    gallery: '#about-us',
    children: 'a#image',
    pswpModule: PhotoSwipe
  });
  lightbox.init();
})

const features = [
  {
    name: computed(() => t('about-us.incentives.0.title')),
    description: computed(() => t('about-us.incentives.0.text')),
    icon: ArrowPathIcon,
    text: '10+',
  },
  {
    name: computed(() => t('about-us.incentives.1.title')),
    description: computed(() => t('about-us.incentives.1.text')),
    icon: UserGroupIcon,
  },
  {
    name: computed(() => t('about-us.incentives.2.title')),
    description: computed(() => t('about-us.incentives.2.text')),
    icon: BuildingOfficeIcon,
  },
  {
    name: computed(() => t('about-us.incentives.3.title')),
    description: computed(() => t('about-us.incentives.3.text')),
    icon: GlobeAltIcon,
  },
]
</script>
