import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import Materials from '../views/Materials.vue'
import LoginView from '../views/LoginView.vue'

const routes = [
  {
    path: '/login',
    name: 'login',
	  meta: {
		  title: 'Вход',
	  },
    component: LoginView
  },
  {
    path: '/',
    name: 'home',
	  meta: {
		  title: 'Главная',
	  },
    component: HomeView
  },
  {
    path: '/m',
    name: 'materials',
	  meta: {
		  title: 'Материалы',
		  layout: 'MainLayout',
	  },
    component: Materials
  },
  /*{
    path: '/about',
    name: 'about',
	  meta: {
		  title: 'О нас',
		  layout: 'MainLayout',
	  },
    component: function () {
      return import('../views/HomeView.vue')
    }
  },*/
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
