<template>
  <div class="bg-white py-12 sm:py-24 lg:py-48" id="materials">
    <div class="mx-auto max-w-7xl">
      <div class="mx-auto max-w-4xl text-center">
        <p class="mb-16 text-2xl font-bold tracking-tight text-gray-900 sm:text-4xl">{{ t('study_materials') }}</p>
      </div>

      <div class="px-4 sm:px-0">
        <h3 class="text-base font-semibold leading-7 text-gray-900">{{ t('prombezitr') }}</h3>
      </div>
      <div class="mt-6 border-t border-gray-100">
        <dl class="divide-y divide-gray-100">
          <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt class="text-sm font-medium leading-6 text-gray-900">{{ t('materials') }}</dt>
            <dd class="mt-2 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
              <ul role="list" class="divide-y divide-gray-100 rounded-md border border-gray-200">
                <li class="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6">
                  <div class="flex w-0 flex-1 items-center">
                    <PaperClipIcon class="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                    <div class="ml-4 flex min-w-0 flex-1 gap-2">
                      <span class="truncate font-medium">Закон_о_Гражданской_защите_на_20-04-2023.docx</span>
                      <!--<span class="flex-shrink-0 text-gray-400">2.4mb</span>-->
                    </div>
                  </div>
                  <div class="ml-4 flex-shrink-0">
                    <a href="/materials/Закон_о_Гражданской_защите_на_20-04-2023.docx" class="font-medium text-indigo-600 hover:text-indigo-500">{{ t('download') }}</a>
                  </div>
                </li>
                <li class="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6">
                  <div class="flex w-0 flex-1 items-center">
                    <PaperClipIcon class="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                    <div class="ml-4 flex min-w-0 flex-1 gap-2">
                      <span class="truncate font-medium">Правила_обучения_по_ПБ_от_14-12-2022.docx</span>
                      <!--<span class="flex-shrink-0 text-gray-400">4.5mb</span>-->
                    </div>
                  </div>
                  <div class="ml-4 flex-shrink-0">
                    <a href="/materials/Правила_обучения_по_ПБ_от_14-12-2022.docx" class="font-medium text-indigo-600 hover:text-indigo-500">{{ t('download') }}</a>
                  </div>
                </li>
              </ul>
            </dd>
          </div>
        </dl>
      </div>
      <div class="border-t border-gray-100">
        <dl class="divide-y divide-gray-100">
          <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt class="text-sm font-medium leading-6 text-gray-900">{{ t('exam') }}</dt>
            <dd class="mt-2 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
              <ul role="list" class="divide-y divide-gray-100 rounded-md border border-gray-200">
                <li class="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6">
                  <div class="flex w-0 flex-1 items-center">
                    <PaperClipIcon class="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                    <div class="ml-4 flex min-w-0 flex-1 gap-2">
                      <span class="truncate font-medium">1 {{ t('variant') }}</span>
                      <!--<span class="flex-shrink-0 text-gray-400">2.4mb</span>-->
                    </div>
                  </div>
                  <div class="ml-4 flex-shrink-0">
                    <a href="/materials/Промышленная_безопасность_ИТР_Тестовые_вопросы_1_вариант.docx" class="font-medium text-indigo-600 hover:text-indigo-500">{{ t('download') }}</a>
                  </div>
                </li>
                <li class="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6">
                  <div class="flex w-0 flex-1 items-center">
                    <PaperClipIcon class="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                    <div class="ml-4 flex min-w-0 flex-1 gap-2">
                      <span class="truncate font-medium">2 {{ t('variant') }}</span>
                      <!--<span class="flex-shrink-0 text-gray-400">4.5mb</span>-->
                    </div>
                  </div>
                  <div class="ml-4 flex-shrink-0">
                    <a href="/materials/Промышленная_безопасность_ИТР_Тестовые_вопросы_2_вариант.docx" class="font-medium text-indigo-600 hover:text-indigo-500">{{ t('download') }}</a>
                  </div>
                </li>
                <li class="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6">
                  <div class="flex w-0 flex-1 items-center">
                    <PaperClipIcon class="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                    <div class="ml-4 flex min-w-0 flex-1 gap-2">
                      <span class="truncate font-medium">{{ t('list_of_answers') }}</span>
                      <!--<span class="flex-shrink-0 text-gray-400">2.4mb</span>-->
                    </div>
                  </div>
                  <div class="ml-4 flex-shrink-0">
                    <a href="/materials/Промышленная_безопасность_ИТР_Лист_ответов.docx" class="font-medium text-indigo-600 hover:text-indigo-500">{{ t('download') }}</a>
                  </div>
                </li>
              </ul>
            </dd>
          </div>
        </dl>
      </div>

      <div class="px-4 sm:px-0 mt-16">
        <h3 class="text-base font-semibold leading-7 text-gray-900">{{ t('prombezrabochie') }}</h3>
      </div>
      <div class="mt-6 border-t border-gray-100">
        <dl class="divide-y divide-gray-100">
          <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt class="text-sm font-medium leading-6 text-gray-900">{{ t('exam') }}</dt>
            <dd class="mt-2 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
              <ul role="list" class="divide-y divide-gray-100 rounded-md border border-gray-200">
                <li class="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6">
                  <div class="flex w-0 flex-1 items-center">
                    <PaperClipIcon class="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                    <div class="ml-4 flex min-w-0 flex-1 gap-2">
                      <span class="truncate font-medium">1 {{ t('variant') }}</span>
                    </div>
                  </div>
                  <div class="ml-4 flex-shrink-0">
                    <a href="/materials/Промышленная_безопасность_Рабочие_профессии_Тестовые_вопросы.docx" class="font-medium text-indigo-600 hover:text-indigo-500">{{ t('download') }}</a>
                  </div>
                </li>
                
                <li class="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6">
                  <div class="flex w-0 flex-1 items-center">
                    <PaperClipIcon class="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                    <div class="ml-4 flex min-w-0 flex-1 gap-2">
                      <span class="truncate font-medium">{{ t('list_of_answers') }}</span>
                    </div>
                  </div>
                  <div class="ml-4 flex-shrink-0">
                    <a href="/materials/Промышленная_безопасность_Рабочие профессии_Лист_ответов.docx" class="font-medium text-indigo-600 hover:text-indigo-500">{{ t('download') }}</a>
                  </div>
                </li>
              </ul>
            </dd>
          </div>
        </dl>
      </div>

      <div class="px-4 sm:px-0 mt-16">
        <h3 class="text-base font-semibold leading-7 text-gray-900">{{ t('biot') }}</h3>
      </div>
      <div class="mt-6 border-t border-gray-100">
        <dl class="divide-y divide-gray-100">
          <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt class="text-sm font-medium leading-6 text-gray-900">{{ t('materials') }}</dt>
            <dd class="mt-2 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
              <ul role="list" class="divide-y divide-gray-100 rounded-md border border-gray-200">
                <li class="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6">
                  <div class="flex w-0 flex-1 items-center">
                    <PaperClipIcon class="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                    <div class="ml-4 flex min-w-0 flex-1 gap-2">
                      <span class="truncate font-medium">Трудовой_Кодекс_от_03-01-2023.docx</span>
                      <!--<span class="flex-shrink-0 text-gray-400">2.4mb</span>-->
                    </div>
                  </div>
                  <div class="ml-4 flex-shrink-0">
                    <a href="/materials/Трудовой_Кодекс_от_03-01-2023.docx" class="font-medium text-indigo-600 hover:text-indigo-500">{{ t('download') }}</a>
                  </div>
                </li>
                <li class="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6">
                  <div class="flex w-0 flex-1 items-center">
                    <PaperClipIcon class="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                    <div class="ml-4 flex min-w-0 flex-1 gap-2">
                      <span class="truncate font-medium">Правила_и_сроки_проведения_обучения_по_БиОТ_на_30-03-2023.rus.docx</span>
                      <!--<span class="flex-shrink-0 text-gray-400">4.5mb</span>-->
                    </div>
                  </div>
                  <div class="ml-4 flex-shrink-0">
                    <a href="/materials/Правила_и_сроки_проведения_обучения_по_БиОТ_на_30-03-2023.rus.docx" class="font-medium text-indigo-600 hover:text-indigo-500">{{ t('download') }}</a>
                  </div>
                </li>
              </ul>
            </dd>
          </div>
        </dl>
      </div>
      <div class="border-t border-gray-100">
        <dl class="divide-y divide-gray-100">
          <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt class="text-sm font-medium leading-6 text-gray-900">{{ t('exam') }}</dt>
            <dd class="mt-2 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
              <ul role="list" class="divide-y divide-gray-100 rounded-md border border-gray-200">
                <li class="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6">
                  <div class="flex w-0 flex-1 items-center">
                    <PaperClipIcon class="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                    <div class="ml-4 flex min-w-0 flex-1 gap-2">
                      <span class="truncate font-medium">1 {{ t('variant') }}</span>
                      <!--<span class="flex-shrink-0 text-gray-400">2.4mb</span>-->
                    </div>
                  </div>
                  <div class="ml-4 flex-shrink-0">
                    <a href="/materials/Безопасность_и_охрана_труда_Тестовые_вопросы_1_вариант.docx" class="font-medium text-indigo-600 hover:text-indigo-500">{{ t('download') }}</a>
                  </div>
                </li>
                <li class="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6">
                  <div class="flex w-0 flex-1 items-center">
                    <PaperClipIcon class="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                    <div class="ml-4 flex min-w-0 flex-1 gap-2">
                      <span class="truncate font-medium">2 {{ t('variant') }}</span>
                      <!--<span class="flex-shrink-0 text-gray-400">4.5mb</span>-->
                    </div>
                  </div>
                  <div class="ml-4 flex-shrink-0">
                    <a href="/materials/Безопасность_и_охрана_труда_Тестовые_вопросы_2_вариант.docx" class="font-medium text-indigo-600 hover:text-indigo-500">{{ t('download') }}</a>
                  </div>
                </li>
                <li class="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6">
                  <div class="flex w-0 flex-1 items-center">
                    <PaperClipIcon class="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                    <div class="ml-4 flex min-w-0 flex-1 gap-2">
                      <span class="truncate font-medium">{{ t('list_of_answers') }}</span>
                      <!--<span class="flex-shrink-0 text-gray-400">2.4mb</span>-->
                    </div>
                  </div>
                  <div class="ml-4 flex-shrink-0">
                    <a href="/materials/Безопасность_и_охрана_труда_Лист_ответов.docx" class="font-medium text-indigo-600 hover:text-indigo-500">{{ t('download') }}</a>
                  </div>
                </li>
              </ul>
            </dd>
          </div>
        </dl>
      </div>

      <div class="px-4 sm:px-0 mt-16">
        <h3 class="text-base font-semibold leading-7 text-gray-900">{{ t('pozhbez') }}</h3>
      </div>
      <div class="mt-6 border-t border-gray-100">
        <dl class="divide-y divide-gray-100">
          <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt class="text-sm font-medium leading-6 text-gray-900">{{ t('materials') }}</dt>
            <dd class="mt-2 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
              <ul role="list" class="divide-y divide-gray-100 rounded-md border border-gray-200">
                <li class="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6">
                  <div class="flex w-0 flex-1 items-center">
                    <PaperClipIcon class="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                    <div class="ml-4 flex min-w-0 flex-1 gap-2">
                      <span class="truncate font-medium">Правила_обучения_ПТМ_от_21-12-2022.docx</span>
                      <!--<span class="flex-shrink-0 text-gray-400">2.4mb</span>-->
                    </div>
                  </div>
                  <div class="ml-4 flex-shrink-0">
                    <a href="/materials/Правила_обучения_ПТМ_от_21-12-2022.docx" class="font-medium text-indigo-600 hover:text-indigo-500">{{ t('download') }}</a>
                  </div>
                </li>
                <li class="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6">
                  <div class="flex w-0 flex-1 items-center">
                    <PaperClipIcon class="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                    <div class="ml-4 flex min-w-0 flex-1 gap-2">
                      <span class="truncate font-medium">Пособие_по_Пожарной_безопасности.docx</span>
                      <!--<span class="flex-shrink-0 text-gray-400">2.4mb</span>-->
                    </div>
                  </div>
                  <div class="ml-4 flex-shrink-0">
                    <a href="/materials/Пособие_по_Пожарной_безопасности.docx" class="font-medium text-indigo-600 hover:text-indigo-500">{{ t('download') }}</a>
                  </div>
                </li>
              </ul>
            </dd>
          </div>
        </dl>
      </div>
      <div class="border-t border-gray-100">
        <dl class="divide-y divide-gray-100">
          <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt class="text-sm font-medium leading-6 text-gray-900">{{ t('exam') }}</dt>
            <dd class="mt-2 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
              <ul role="list" class="divide-y divide-gray-100 rounded-md border border-gray-200">
                <li class="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6">
                  <div class="flex w-0 flex-1 items-center">
                    <PaperClipIcon class="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                    <div class="ml-4 flex min-w-0 flex-1 gap-2">
                      <span class="truncate font-medium">1 {{ t('variant') }}</span>
                      <!--<span class="flex-shrink-0 text-gray-400">2.4mb</span>-->
                    </div>
                  </div>
                  <div class="ml-4 flex-shrink-0">
                    <a href="/materials/Пожарная_безопасность_Тестовые_вопросы_1_вариант.docx" class="font-medium text-indigo-600 hover:text-indigo-500">{{ t('download') }}</a>
                  </div>
                </li>
                <li class="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6">
                  <div class="flex w-0 flex-1 items-center">
                    <PaperClipIcon class="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                    <div class="ml-4 flex min-w-0 flex-1 gap-2">
                      <span class="truncate font-medium">{{ t('list_of_answers') }}</span>
                      <!--<span class="flex-shrink-0 text-gray-400">2.4mb</span>-->
                    </div>
                  </div>
                  <div class="ml-4 flex-shrink-0">
                    <a href="/materials/Пожарная_безопасность_Лист_ответов.docx" class="font-medium text-indigo-600 hover:text-indigo-500">{{ t('download') }}</a>
                  </div>
                </li>
              </ul>
            </dd>
          </div>
        </dl>
      </div>
    </div>
  </div>
</template>

<script setup>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import { PaperClipIcon } from '@heroicons/vue/20/solid'
import { computed, onMounted } from 'vue'
import { useI18n } from 'vue-i18n'
import {
  ArrowPathIcon,
  GlobeAltIcon,
  BuildingOfficeIcon,
  UserGroupIcon,
  BuildingOffice2Icon,
  AtSymbolIcon,
  PhoneIcon,
  DevicePhoneMobileIcon
} from '@heroicons/vue/24/outline'

const { t } = useI18n({
  sync: true,
  messages: {
    ru: {
      'study_materials': 'Материалы для обучения',
      'materials': 'Материалы',
      'prombezitr': 'Промышленная безопасность (Инженерно-технические работники)',
      'prombezrabochie': 'Промышленная безопасность (Рабочие профессии)',
      'biot': 'Безопасность и охрана труда',
      'pozhbez': 'Пожарная безопасность',
      'exam': 'Экзамен',
      'list_of_answers': 'Лист ответов',
      'download': 'Скачать',
      'variant': 'вариант',
    },
    kk: {
      'study_materials': 'Оқу материалдары',
      'materials': 'Материалдар',
      'prombezitr': 'Өнеркәсіптік қауіпсіздік (Инженерлік-техникалық қызметкерлер)',
      'prombezrabochie': 'Өнеркәсіптік қауіпсіздік (Жұмысшы мамандықтары)',
      'biot': 'Еңбек қауіпсіздігі және еңбекті қорғау',
      'pozhbez': 'Өрт қауіпсіздігі',
      'exam': 'Емтихан',
      'list_of_answers': 'Жауап парағы',
      'download': 'Жүктеу',
      'variant': 'вариант',
    },
    en: {
      'study_materials': 'Learning materials',
      'materials': 'Materials',
      'prombezitr': 'Industrial Safety (Engineering and Technical Workers)',
      'prombezrabochie': 'Industrial Safety (Working professions)',
      'biot': 'Occupational safety and health',
      'pozhbez': 'Fire safety',
      'exam': 'Exam',
      'list_of_answers': 'Answer sheet',
      'download': 'Download',
      'variant': 'variant',
    },
  }
})
</script>
