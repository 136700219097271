<template>
  <div class="w-full max-w-md space-y-8">
    <div>
      <h2 class="mt-6 text-center text-xl font-bold tracking-tight text-gray-900">{{ t('enter') }}</h2>
    </div>
    <form class="mt-8 space-y-6" action="#" method="POST">
      <input type="hidden" name="remember" value="true" />
      <div class="-space-y-px rounded-md shadow-sm">
        <div>
          <label for="username" class="sr-only">{{ t('username') }}</label>
          <input id="username" name="username" type="text" autocomplete="username" required="" class="relative block w-full appearance-none rounded-none rounded-t-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm" :placeholder="t('username')" />
        </div>
        <div>
          <label for="password" class="sr-only">{{ t('password') }}</label>
          <input id="password" name="password" type="password" autocomplete="current-password" required="" class="relative block w-full appearance-none rounded-none rounded-b-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm" :placeholder="t('password')" />
        </div>
      </div>

      <div class="flex items-center justify-between">
        <div class="flex items-center">
          <input id="remember-me" name="remember-me" type="checkbox" class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500" />
          <label for="remember-me" class="ml-2 block text-sm text-gray-900">{{ t('remember') }}</label>
        </div>

        <div class="text-sm">
          <a href="#" class="font-medium text-indigo-600 hover:text-indigo-500">{{ t('forgot') }}</a>
        </div>
      </div>

      <div>
        <button type="submit" class="group relative flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
          <span class="absolute inset-y-0 left-0 flex items-center pl-3">
            <LockClosedIcon class="h-5 w-5 text-indigo-500 group-hover:text-indigo-400" aria-hidden="true" />
          </span>
          {{ t('login') }}
        </button>
      </div>
    </form>
  </div>
</template>

<script setup>
import { LockClosedIcon } from '@heroicons/vue/20/solid'
import { useI18n } from 'vue-i18n'
const { t } = useI18n({})
</script>

<i18n>
{
  "ru": {
    "enter": "Вход в личный кабинет",
    "username": "ИИН или Номер телефона",
    "password": "Пароль",
    "remember": "Запомнить меня",
    "forgot": "Забыли пароль?",
    "login": "Войти",
  },
  "kk": {
    "enter": "Жеке кабинетке кіру",
    "username": "ЖСН немесе Телефон нөмірі",
    "password": "Пароль",
    "remember": "Мені есте сақтаңыз",
    "forgot": "Құпия сөзді ұмыттыңыз ба?",
    "login": "Кіру",
  },
  "en": {
    "enter": "Login to your personal account",
    "username": "IIN or Phone number",
    "password": "Password",
    "remember": "Remember me",
    "forgot": "Forgot your password?",
    "login": "Login",
  },
}
</i18n>
