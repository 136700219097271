import { createI18n } from 'vue-i18n'
import ru from '@/lang/ru'
import kk from '@/lang/kk'
import en from '@/lang/en'

export default createI18n({
	locale: getLocale(),
	fallbackLocale: 'ru',
	legacy: false,
	messages: {
		ru: ru,
		kk: kk,
		en: en,
	}
});

function getLocale() {
	let locale = localStorage.getItem('locale') || (navigator.language || navigator.userLanguage).split('-')[0]
	return locale
}
