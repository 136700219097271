export default {
	langname: 'Қаз',
	company: {
		'name': 'Компания АЗиЯ 2012 ЖШС',
	},
	ui: {
		'logo': 'Логотип',
		'back': 'Артқа',
		'login': 'Кіру',
		'open-menu': 'Мәзірді ашыңыз',
		'close-menu': 'Мәзірді жабу',
	},
	footer: {
		'rights': '"Компания АЗиЯ 2012" ЖШС. Қызметтердің құнына қатысты сайтта ұсынылған ақпарат ақпараттық сипатқа ие және ешқандай жағдайда жария оферта болып табылмайды.',
	},
	menu: {
		'home': 'Басты бет',
		'about-us': 'Біз туралы',
    'prices': 'Баға тізімі',
    'contacts': 'Байланыс',
    'requisites': 'Деректемелер',
	},
}
