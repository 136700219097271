<template>
  <footer class="mx-auto max-w-7xl p-4 bg-white rounded-lg md:py-8 dark:bg-gray-900">
    <div class="sm:flex sm:items-center sm:justify-between">
        <router-link :to="{ name: 'home' }" class="flex items-center mb-4 sm:mb-0">
          <img src="@/assets/logo-dark.svg" class="h-8 mr-3" :alt="$t('ui.logo')" />
        </router-link>
        <ul class="flex flex-wrap items-center mb-6 text-sm text-gray-500 sm:mb-0 dark:text-gray-400 space-x-4 md:space-x-6">
          <li v-for="item in menu" key="item.url">
            <a :href="item.url" class="hover:underline">{{ item.name.value }}</a>
          </li>
        </ul>
    </div>
    <hr class="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8" />
    <span class="block mx-auto max-w-4xl text-sm text-gray-500 sm:text-center dark:text-gray-400">{{ $t('footer.rights') }}</span>
  </footer>
</template>

<script setup>
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
const { t } = useI18n()
const menu = [
  {
    name: computed(() => t("menu.home")),
    url: '#home',
  },
  {
    name: computed(() => t("menu.about-us")),
    url: '#about-us',
  },
  {
    name: computed(() => t("menu.prices")),
    url: '#prices',
  },
  {
    name: computed(() => t("menu.contacts")),
    url: '#contacts',
  },
  {
    name: computed(() => t("menu.requisites")),
    url: '#requisites',
  },
];
</script>
