<template>
  <Popover class="relative">
    <div class="mx-auto max-w-7xl px-6">
      <div class="flex items-center justify-between border-b-2 border-gray-100 py-6 lg:justify-start md:space-x-10">
        <div class="flex justify-start lg:w-0 lg:flex-1">
          <router-link :to="{ name: 'home' }">
            <span class="sr-only">{{ $t('company.name') }}</span>
            <img class="h-8 w-auto sm:h-10" :src="require(`@/assets/${ !dark ? 'logo-dark.svg' : 'logo-light.svg' }`)" :alt="$t('ui.logo')" />
          </router-link>
        </div>
        <div class="-my-2 -mr-2 lg:hidden flex-nowrap flex items-center">
          <LanguageSelect :dark="dark" />
          <PopoverButton class="ml-5 inline-flex items-center justify-center rounded-md p-2 focus:outline-none" :class="[ dark ? 'text-white' : 'text-gray-400 hover:bg-gray-100 hover:text-gray-500' ]">
            <span class="sr-only">{{ $t('ui.open-menu') }}</span>
            <Bars3Icon class="h-6 w-6" aria-hidden="true" />
          </PopoverButton>
        </div>
        <PopoverGroup as="nav" class="hidden space-x-8 lg:flex">
          <a v-for="item in menu" :key="item.name" :href="item.url" class="text-base font-medium hover:text-amber-600" :class="[ dark ? 'text-white' : 'text-gray-500' ]">{{ item.name.value }}</a>
        </PopoverGroup>
        <div class="hidden items-center justify-end lg:flex lg:flex-1 lg:w-0">
          <LanguageSelect :dark="dark" class="mr-4" />
          <router-link :to="{ name: 'login' }" class="inline-flex items-center justify-center whitespace-nowrap rounded-md border border-transparent bg-blue-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-blue-700">{{ $t('ui.login') }}</router-link>
        </div>
      </div>
    </div>
    <transition enter-active-class="duration-200 ease-out" enter-from-class="opacity-0 scale-95" enter-to-class="opacity-100 scale-100" leave-active-class="duration-100 ease-in" leave-from-class="opacity-100 scale-100" leave-to-class="opacity-0 scale-95">
      <PopoverPanel focus class="absolute inset-x-0 top-0 origin-top-right transform p-2 transition lg:hidden">
        <div class="divide-y-2 divide-gray-50 rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
          <div class="px-5 py-5">
            <div class="flex items-center justify-between">
              <div>
                <img class="h-8 w-auto" src="@/assets/logo-dark.svg" alt="Your Company" />
              </div>
              <div class="-mr-2">
                <PopoverButton class="inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none">
                  <span class="sr-only">{{ $t('ui.close-menu') }}</span>
                  <XMarkIcon class="h-6 w-6" aria-hidden="true" />
                </PopoverButton>
              </div>
            </div>
          </div>
          <div class="px-5 py-5">
            <nav class="grid gap-y-8">
              <PopoverButton v-for="item in menu" :key="item.name" >
                <a :href="item.url" class="-m-3 flex items-center rounded-md p-3 hover:bg-gray-50">
                  <component :is="item.icon" class="h-6 w-6 flex-shrink-0 text-blue-600" aria-hidden="true" />
                  <span class="ml-3 text-base font-medium text-gray-900">{{ item.name.value }}</span>
                </a>
              </PopoverButton>
            </nav>
          </div>
          <div class="space-y-5 py-5 px-5">
            <router-link :to="{ name: 'login' }" class="flex w-full items-center justify-center rounded-md border border-transparent bg-blue-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-blue-700">{{ $t('ui.login') }}</router-link>
          </div>
        </div>
      </PopoverPanel>
    </transition>
  </Popover>
</template>

<script setup>
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import {
  Popover,
  PopoverButton,
  PopoverGroup,
  PopoverPanel,
} from '@headlessui/vue'
import {
  HomeIcon,
  UserGroupIcon,
  BanknotesIcon,
  ChatBubbleLeftRightIcon,
  ListBulletIcon,
  ArrowPathIcon,
  Bars3Icon,
  XMarkIcon,
} from '@heroicons/vue/24/outline'
import LanguageSelect from '@/components/LanguageSelect.vue'

const props = defineProps({
  dark: {
    type: Boolean,
    default: false,
  }
});

const { t } = useI18n()

const menu = [
  {
    name: computed(() => t("menu.home")),
    url: '/#home',
    icon: HomeIcon,
  },
  {
    name: computed(() => t("menu.about-us")),
    url: '/#about-us',
    icon: UserGroupIcon,
  },
  {
   name: computed(() => t("menu.prices")),
   url: '/#prices',
   icon: BanknotesIcon,
  },
  {
    name: computed(() => t("menu.contacts")),
    url: '/#contacts',
    icon: ChatBubbleLeftRightIcon,
  },
  {
    name: computed(() => t("menu.requisites")),
    url: '/#requisites',
    icon: ListBulletIcon,
  },
];
</script>
