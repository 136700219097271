export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "ru": {
        "enter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вход в личный кабинет"])},
        "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ИИН или Номер телефона"])},
        "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароль"])},
        "remember": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Запомнить меня"])},
        "forgot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Забыли пароль?"])},
        "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Войти"])}
      },
      "kk": {
        "enter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жеке кабинетке кіру"])},
        "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ЖСН немесе Телефон нөмірі"])},
        "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароль"])},
        "remember": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мені есте сақтаңыз"])},
        "forgot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Құпия сөзді ұмыттыңыз ба?"])},
        "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кіру"])}
      },
      "en": {
        "enter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login to your personal account"])},
        "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IIN or Phone number"])},
        "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
        "remember": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remember me"])},
        "forgot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forgot your password?"])},
        "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])}
      }
    }
  })
}
