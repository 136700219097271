import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import MainLayout from './layouts/MainLayout'
import './assets/tailwind.css'
import i18n from '@/modules/i18n'

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'
/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
/* import specific icons */
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
/* add icons to the library */
library.add(fas)
library.add(far)
library.add(fab)

const app = createApp(App)
app.use(store)
app.use(router)
app.use(i18n)
app.component('font-awesome-icon', FontAwesomeIcon)
app.component('MainLayout', MainLayout)
app.mount('#app')
